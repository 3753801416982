@import url(https://fonts.googleapis.com/css2?family=Pontano+Sans:wght@300..700&display=swap);
.content-app-loader {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #001e74;
  --second-color: #9dc817;
  --third-color: #001e74;
  --fourth-color: #9dc817;
  --fifth-color: #001860;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  grid-gap: 8px;
  gap: 8px;
  height: 150px;
}

.app-loading span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.app-loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.app-loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.app-loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.app-loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

@keyframes scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }
  20% {
    transform: scaleY(1);
  }
}

* {
  font-family: "Pontano Sans", sans-serif;
}

html {
  scrollbar-width: none;
}

.custom-datatable {
  font-size: 14px;
}

#root {
  height: 100vh;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  opacity: 0.4;
  cursor: default;
}

.form-select {
  /* desaparecer esto cuando se cambie todo lo importante componentes de antd */
  font-size: 99% !important;
  border-radius: 3px !important;
}

.form-control {
  /* desaparecer esto cuando se cambie todo lo importante componentes de antd */
  font-size: 99% !important;
  height: 34.5px;
  border-radius: 3px !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

@media only screen and (max-width: 600px) {
  .res-scheduler {
    width: 16rem !important;
  }

  .max-scheduler {
    width: 16rem !important;
  }
}

@media only screen and (min-width: 600px) {
  .res-scheduler {
    width: 32rem !important;
  }

  .max-scheduler {
    width: 32rem !important;
  }
}

@media only screen and (min-width: 768px) {
  .res-scheduler {
    width: 46rem !important;
  }

  .max-scheduler {
    width: 46rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .res-scheduler {
    width: 50rem !important;
  }

  .max-scheduler {
    width: 50rem !important;
  }
}

@media only screen and (min-width: 1200px) {
  .res-scheduler {
    width: 53rem !important;
  }

  .max-scheduler {
    width: 63rem !important;
  }

  .scheduler-res {
    max-width: 53rem !important;
  }
}

.modal-open .modal {
  scrollbar-width: none;
  padding-left: 0 !important;
}

.modal-content {
  border-radius: 13px;
  border: none;
}

.rbt-input-hint {
  display: none !important;
}

.rbt-input-main {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none;
}

.btn-success,
.btn-primary,
.btn-warning,
.btn-info,
.btn-secondary,
.btn-danger,
.btn-dark {
  border: none;
}

.ant-tabs-tab {
  padding-top: 2px;
}

label {
  margin-bottom: 2px;
}

button {
  border-radius: 5px !important;
  border: none;
}

.div-inventory-photo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 100%;
  height: 110px;
  overflow: hidden;
  border-radius: 10px;
  padding: 10px;
}

.div-inventory-photo img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.radius-left-0px {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.radius-top-8px {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.radius-8px {
  border-radius: 8px !important;
}

.cursor-pointer {
  cursor: pointer;
}

input[type="search"] {
  padding-left: 28px;
  background-image: url("/search-icon.svg");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 28px 16px;
}

.form-phone-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-phone-text {
  display: block;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 12px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #444;
  outline: none;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.form-phone-text:disabled {
  cursor: not-allowed;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
}

.carousel-indicators li {
  background-color: rgba(0, 0, 0, 0.8);
}

._loading_overlay_overlay {
  background: none !important;
  color: #000 !important;
  font-weight: 600 !important;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hover-zoom {
  cursor: pointer;
  transition: transform 0.1s;
}

.hover-zoom:hover {
  transform: scale(1.015);
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  font-weight: 610;
  color: #3476bd;
}

.nav-tabs .nav-link.active {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.modal-header .close span:first-of-type {
  font-size: 35px;
}

.no-outline:focus {
  outline: none;
  box-shadow: none;
}

.ant-table-thead > tr > th {
  font-weight: bold;
  font-size: 0.8rem;
}

.ant-table-tbody > tr > td {
  font-size: 0.75rem;
}

.ant-carousel .slick-dots li button {
  background-color: rgba(0, 0, 0, 0.5);
}

.ant-carousel .slick-dots li.slick-active button {
  background-color: rgba(0, 0, 0, 1);
}

.ant-carousel .slick-dots {
  bottom: 1px;
}

.ant-spin-dot-item:nth-child(1) {
  background-color: #001e74;
}

.ant-spin-dot-item:nth-child(2) {
  background-color: #9dc817;
}

.ant-spin-dot-item:nth-child(3) {
  background-color: #001860;
}

.ant-spin-dot-item:nth-child(4) {
  background-color: #9dc817;
}

.ant-table-bordered {
  border: 1px solid #e4e4e4;
}

